import { LoaderFunctionArgs, json } from "@remix-run/cloudflare";
import { MetaFunction } from "@remix-run/cloudflare";
import { getGraphCms, getStage } from "../../services/graphcms";
import { getSdk, Locale, Brand } from "../../generated/schema.graphcms";
import HomeIntroMobile from "./components/home/IntroMobile";
import HomeIntroDesktop from "./components/home/IntroDesktop";
import Divbar from "./components/home/Divbar";
import LearnMore from "./components/home/LearnMore";
import Sections from "./components/home/Sections";
import PlayNowModal from "./components/home/PlayNowModal";
import { localeTokenMap } from "~/utils/localeTokenMap";
import { getDictionary } from "~/utils/dictionaries";
import { useLoaderData, useParams } from "@remix-run/react";

import { mergeMeta } from "../../metatags/mergeMeta";
const slug = "sideswipe-home";

export const meta: MetaFunction<typeof loader> = mergeMeta<typeof loader>(
  ({ data, params }) => {
    const { lang } = params;
    const { page, url } = data;

    const u = new URL(url);

    return [
      { title: "Rocket League Sideswipe" },
      { property: "description", content: page.description },
      { property: "url", content: `/${lang}` },

      { property: "og:title", content: page.title },
      { property: "og:description", content: page.description },
      { property: "og:url", content: `/${lang}` },
      {
        property: "og:site_name",
        content: "Rocket Leauge Sideswipe Official Website",
      },
      {
        property: "og:image",
        content: `${u.origin}/images/RLS_KeyArt_Primary_Horiz_RGB_4K.jpg`,
      },
      { property: "og:lang", content: lang },
      { property: "og:type", content: "website" },

      { property: "twitter:card", content: "summary_large_image" },
      { property: "twitter:title", content: "Rocket League ® - Official Site" },
    ];
  }
);

async function getPageData(
  env: Env,
  preview: boolean,
  mappedLocaleToken: string
) {
  const { GetPage } = getSdk(getGraphCms(env, preview));
  const pageData = await GetPage({
    locale: [mappedLocaleToken, "en"] as Locale[],
    slug,
    stage: getStage(false),
  });
  return pageData.page;
}

async function getArticlesData(
  env: Env,
  preview: boolean,
  mappedLocaleToken: string
) {
  const { GetArticles } = getSdk(getGraphCms(env, preview));
  const articleData = await GetArticles({
    locale: [mappedLocaleToken, "en"] as Locale[],
    website_contains_all: ["Sideswipe", "Sideswipe"] as Brand[],
    limit: 2,
    offset: 0,
  });
  return articleData;
}

export default function Home(): JSX.Element {
  const { page, articleData, dict } = useLoaderData<typeof loader>();

  const { lang: locale } = useParams();
  const mappedLocaleToken = localeTokenMap(locale);
  const lowerSections = page?.sections.slice(1);

  return (
    <>
      <div className="is-hidden-desktop">
        <HomeIntroMobile locale={locale} copy={dict} tileData={articleData} />
      </div>
      <div className="is-hidden-touch">
        <HomeIntroDesktop locale={locale} copy={dict} tileData={articleData} />
      </div>
      <Divbar />
      <LearnMore data={page?.sections[0]} />
      <Divbar />
      <Sections data={lowerSections} />
      <Divbar />
      <PlayNowModal locale={locale} copy={dict} />
    </>
  );
}

export async function loader({ request, params, context }: LoaderFunctionArgs) {
  const { lang: locale } = params;
  const { env } = context.cloudflare;
  const url = new URL(request.url);
  const preview = url.searchParams.get("preview") === "true";

  const dict = await getDictionary(locale);
  const mappedLocaleToken = localeTokenMap(locale);

  const page = await getPageData(
    env,

    preview,
    mappedLocaleToken
  );
  const articleData = await getArticlesData(env, preview, mappedLocaleToken);

  return json({ page, articleData, dict, url: request.url });
}
