import styles from './styles/Divbar.module.scss'

export default function Divbar():JSX.Element {
      
  return (
    <>
        <div className={`divbar-wrap ${styles.divbarWrap}`}>
          <div className={`divbar ${styles.divBar}`}>
            <img className={`is-hidden-tablet ${styles.divbarMobile}`} src="/images/divbar/sideswipe_divider_mobile.png" alt="Mobile Divider" />
            <img className={`is-hidden-mobile is-hidden-desktop ${styles.divbarTablet}`} src="/images/divbar/sideswipe_divider_tablet.png" alt="Tablet Divider" />
            <img className={`is-hidden-touch ${styles.divbarDesktop}`} src="/images/divbar/sideswipe_divider.png" alt="Desktop Divider" />
          </div>
        </div> 
    </>
  );
}