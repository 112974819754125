import styles from "./styles/Section.module.scss";
import slugify from "~/utils/slugify";

export default function Sections({ data }): JSX.Element {
  return (
    <>
      {data.map((section, index) => (
        <section
          key={section.id}
          id={slugify(section.title)}
          className={`section m-0 p-0 ${styles.sectionWrap}`}
        >
          <div
            className={
              index % 2 == 0
                ? `section-inner.left ${styles.sectionInnerLeft}`
                : `section-inner.right ${styles.sectionInnerRight}`
            }
          >
            <div
              className={
                index % 2 == 0
                  ? `content-wrap ${styles.contentWrapLeft}`
                  : `content-wrap ${styles.contentWrapRight}`
              }
            >
              <img
                src={section.image.url}
                width={1920}
                height={1080}
                alt={`${section.title} image`}
                className="is-hidden-desktop"
              />
              <div
                className={`columns is-mobile is-multiline is-centered has-text-centered is-gapless ${styles.copyWrap}`}
              >
                <div
                  className={`column is-three-quarters-mobile is-four-fifths-tablet is-four-fifths-desktop`}
                >
                  <h2 className={`section-title ${styles.sectionTitle}`}>
                    {section.title}
                  </h2>
                </div>
                <div
                  className={`column is-three-quarters-mobile is-three-fifths-tablet is-four-fifths-desktop`}
                >
                  <p className={`section-copy ${styles.sectionCopy}`}>
                    {section.content}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
}
