import { i18n, Locale } from "../i18n.config";

const localeMap: Record<string, string> = {
  de: "de",
  en: "en",
  "en-us": "en",
  "en-US": "en",
  "es-es": "es_ES",
  "es-ES": "es_ES",
  "es-mx": "es_MX",
  "es-MX": "es_MX",
  fr: "fr",
  it: "it",
  "pt-br": "pt_BR",
  "pt-BR": "pt_BR",
};

export const localeTokenMap = (locale: string): string => {
  if (isValidLocale(locale) == true) {
    return localeMap[locale];
  } else {
    throw new Response(undefined, { status: 404 });
  }
};

const isValidLocale = (locale: string): boolean => {
  if (!locale) {
    return false;
  }
  return i18n.locales.indexOf(locale as Locale) > -1;
};
